
@font-face {
    font-family: 'PTSans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../font/PTSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'PTSans';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('../font/PTSans-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'PTSans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../font/PTSans-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'PTSans';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('../font/PTSans-BoldItalic.ttf') format('truetype');
}