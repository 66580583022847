html {
    background-color: #FFFFFF;
    box-sizing: border-box;
}

body, h1, h2, h3 {
    font-family: 'Raleway', sans-serif;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}
:root {
    --text-primary-color: #505050;
    --heаding-primary-color: #2F2E2E;
    --bg-primary-color: #E3F3F9;
    --link-primary-color: #2679DB;
    --button-primary-color: #FE6341;
    --paragrapgh-color: #F7F7F7;
    --li-color: #384575;
    --li-circle-color: #6EACF5;
    --bg-box: #F7F7F7;
}

body {
    font-size: 17px;
    line-height: 30px;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    scroll-behavior: smooth;
    min-width: 320px;
    margin: 0px;
}

h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 55px;
    line-height: 70px;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 20px 0;
}

h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 33px;
    text-align: center;
    text-transform: uppercase;
    color: var(--heаding-primary-color);
    padding: 25px 0;
}
h3{
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    text-transform: uppercase;
}
p {
    font-size: 17px;
    line-height: 30px;
    color: var(--text-primary-color);
    padding: 20px 0;
}
p+p{
    padding-top: 0px;
}
ul {
    list-style: none;
}

a {
    text-decoration: none;
    color: var(--link-primary-color);
}
@media only screen and (max-width: 867px) {
    h1{
        font-size: 32px;
        line-height: 38px;
    }
    p{
        font-size: 15px;
        line-height: 20px;
    }
}
/* General */

.container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1149px;
}
@media only screen and (max-width: 1025px) {
    .container {
        padding: 0 20px;
    }
}
.grid {
    display: grid;
    grid-gap: 20px;
    gap: 20px;
}
.col2-1{
    grid-template-columns: 2fr 1fr;
}
.text-white{
    color: white;
}
.text-center{
    text-align: center;
}
.bg-grey{
    background-color: var(--bg-box);
}
.bg-black{
    background-color: #161A1D;
}
.block{
    padding: 50px 0;
}
.slide-down {
    height: 0;
    overflow: hidden;
    transition: height 0.2s linear;
}
.m-slide-down-measure p {
    padding-top: 0px;
    margin: 0 !important;
}
.m-visible {
    display: none;
}
.m-hidden {
    display: initial;
}
@media only screen and (max-width: 867px){
    .mobile-detector {
        display: none;
    }

    .m-hidden {
        display: none;
    }

    .m-visible {
        display: initial;
    }

    .m-slide-down {
        -moz-transition: height .5s;
        -ms-transition: height .5s;
        -o-transition: height .5s;
        -webkit-transition: height .5s;
        transition: height .5s;
        height: 0;
        overflow: hidden;
    }
}

/* Read more */

.m-slide-down {
    height: 0;
    overflow: hidden;
    -moz-transition: height 0.5s;
    -ms-transition: height 0.5s;
    -o-transition: height 0.5s;
    -webkit-transition: height 0.5s;
    transition: height 0.5s;
  }
  .m-hidden,
  .mobile-detector {
    display: none;
  }
  .m-visible {
    display: initial;
  }
  .m-hidden {
    display: initial;
  }
  .m-slide-down-measure p {
    margin: 0 !important;
    padding-top: 20px;
  }
  .m-readmore-btn {
    background: transparent;
    border: none;
    text-transform: uppercase;
    font-size: 0;
    font-weight: 500;
    color: white;
    width: 100%;
    margin: 15px auto 50px;
  }
  
  .m-readmore-btn::before {
    cursor: pointer;
    display: inline-block;
    width: 25px;
    height: 25px;
    vertical-align: top;
    margin-left: 5px;
    color: rgb(22, 22, 22);
    font-size: 24px !important;
  }
  
  .m-readmore-btn.read-more-2::before {
    content: "\002B";
  }
  .m-readmore-btn.collapse-2::before {
    content: "\2212";
  }
  
  .m-readmore-btn * {
    pointer-events: none;
  }
  .m-visible {
    display: initial;
  }



/* Banner */
.banner{
    background: #FFFFFF;
    box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 20px 10px 10px;
    display: grid;
    gap: 30px;
    align-items: center; 
    grid-template-columns: 0.8fr 2fr 0.7fr;
}
.banner .logo-box{
    background: #EAF2F8;
    border-radius: 10px;
}
.banner .logo-box a{
    background: url(../image/domainer_logo.svg);
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    display: block;
    font-size: 0;
    padding: 20px 30px;
    height: 138px;
}
.banner .title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 15px;
    color: #090909;
}
.banner .info{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7D7F82;
    position: relative;
    margin-left: 15px;
    padding: 0;
    background-color: transparent;
}
.banner .info:before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
    margin-left: -15px;
    border-radius: 10px;
    background: #F27558;
}
.banner .domaner-btn{
    background: #F27558;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    padding: 23px 5px;
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #000000;
}
.banner .domaner-btn:hover {
    background-color: rgb(249, 173, 141);
}
@media only screen and (max-width: 1090px) {
    .banner .title{
        font-size: 22px;
        line-height: 50px;
        margin-bottom: 10px;
    }
    .banner .info{
        font-size: 18px;
    }
    .banner .domaner-btn{
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
        line-height: 22px;
        display: table;
        margin: 0 auto;
        padding: 23px 30px;
    }
}
  @media only screen and (max-width: 940px) {
    .banner{
        grid-template-columns: 1fr;
        padding: 10px;
    }
    .banner .logo-box a{
        background: url(../image/domainer_logo_mob.svg);
        background-repeat: no-repeat;
        background-position: center center;
        height: 66px;
    }
    .banner .title{
        line-height: 24px;
        text-align: center;
    }
    .banner .info{
        font-size: 16px;
        line-height: 20px;
        text-align: center;
    }
    .banner .info:before{
        content: none;
    }
  }
  @media only screen and (max-width: 440px) {
    .banner .domaner-btn {
        display: block;
        padding: 23px 0px;
    }
  }



/* Header */


.scroll-to-link > *{
    pointer-events: none;
 }
.site-header {
    width: 100%;
    background: #161A1D;
    padding: 0 0 20px;
    position: relative;
}

.site-header .site-logo {
    position: relative;
    padding: 20px 0;
}

.site-header .site-logo .main-logo, .footer .main-logo{
    display: inline-block;
    font-size: 0;
    height: 48px;
    position: relative;
    vertical-align: middle;
    width: 250px;
}
.site-header .site-logo .main-logo{
    background: url(../image/logo.png) no-repeat 0 0/contain;
}
.site-header .intro-entry{
    display: grid;
    grid-template-columns: 3fr 1fr;
    padding: 25px 0;
}


.site-header .intro-entry p{
    width: 60%;
}
.site-header .title{
    z-index: 1;
    position: relative;
}

.title h1 {
    width: 80%;
}

.site-header .main-image::after{
    content: '';
    background-image: url("../image/hero-image.png"); 
    height: 50%; 
    width: 100%;
    right: 0;
    bottom: 0;
    top: 0;
    margin-top: auto; 
    margin-bottom: auto; 
    position: absolute;
    background-position: center right;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1;
}
.site-header .main-image::before {
    content: "";
    position: absolute;
    width: 30%;
    height: 70%;
    right: 0;
    top: 0;
    bottom: 0;
    margin-top: auto; 
    margin-bottom: auto; 
    opacity: 0.8;
    filter: blur(300px);
}
@media (min-width: 1710px) {
    .site-header .main-image::after, .site-header .main-image::before {
        right: 0;
        background-size: contain;
        height: 70%!important;
    }
    .site-header .intro-entry{
        padding: 100px 0;
    }
}
@media (min-width: 868px) and (max-width:1025px) {
    .site-header .main-image::after{
        height: 40%;
    }
}
@media only screen and (max-width: 867px) {
    .site-header{
        padding: 0;
    }
    .site-header .container{
        padding: 0;
    }
    .site-header .intro-entry{
        grid-template-columns: 1fr;
        padding: 0px 20px 40px 20px;
    }
    .main-image{
      display: none;
    }
    .site-header .main-image::before{
        width: 60%;
    }
    .site-header .main-image::after{
       content: none;
    }
    .site-header .intro-entry p{
        width: 100%;
    }
    .site-header .site-logo{
        padding-left: 20px;
        padding-right: 20px;
    }
}

    .img2 {
        background: url(../image/what-is-virtual-real-estate.png);
        margin-top: auto;
        max-width: 100%;
      
     
    }

    .intro {
        font-weight: 300;
        font-size: 17px;
        line-height: 30px;
        color: #272525;
      }
      
      .header-text {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 17px;
        line-height: 30px;
        color: #272525;
        mix-blend-mode: normal;
      }


/* Navigation */


.bullets {
    margin: 0 auto;
    list-style: disc;
    padding-bottom: 35px;
    display: table;
   
    
}

.bullets2 {
    list-style: none;
    padding-bottom: 35px;
    text-indent: 22px;

    
}

.bullets-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    
    
}

.bullets-wrapper .bullets2:first-child {
    margin-right: 166px;
   
}

ul.bullets2 li::before {
    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: red; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block 25px; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
    font-size: 20px;
  }

.table1 {
    display: table;
    margin: 0 auto;
    
}



.text-b-table {
    padding-left: 20px;

}

.btn-group{
    box-shadow: 0px 6px 17px rgba(0, 0, 0, 0.14);
    border-radius: 22px;
    width: fit-content;
    display: flex;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    top: 50px;
}
.btn {
    display: inline-block;
    font-weight: 400;
    cursor: pointer;
    color: #FFFFFF;
    background-color: #AC2757;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: none;
    padding: 20px 45px;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 22px;
    transition: background-color .15s ease-in-out;
}
.btn:hover {
    color: #fff;
    background-color: #4398E6;
}
.btn-group li:not(:last-child):not(.dropdown-toggle) .btn{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 1px solid #E44C83;
}
.btn-group li:not(:first-child) .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.btn-group .btn+.btn{
    margin-left: -5px;
}
.btn-group>.btn:first-child {
    margin-left: 0;
}
.button {
    -webkit-backface-visibility: hidden;
    background-color:#AC2757;
    border-radius: 69px;
    display: inline-block;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    padding: 15px 30px;
    white-space: nowrap;
    z-index: 1;
    color: #fff;
    position: relative;
    text-decoration: none;
}
.button:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 69px;
    background-image: linear-gradient(94.06deg, var(--button-primary-color) 1.25%, var(--button-primary-color) 71.25%);
    transition: opacity 0.5s ease-out;
    z-index: 2;
    opacity: 0;
  }
  .button:hover:after {
    opacity: 1;
  }
  .button span {
    position: relative;
    z-index: 3;
  }
    @media (min-width: 1300px){
        a.button {
            min-width: 170px;
        }
    }
    @media only screen and (max-width: 1067px){
        a.button {
            box-shadow: none;
            display: block;
            min-width: auto;
            padding: 20px;
            text-align: center;
        }
        .site-header .site-nav{
            z-index: auto;
        }
        .nav-visible .site-logo{
            padding-top: 10px;
        }
        .site-nav .icon{
            border-top: 2px solid white;
            height: 25px;
            width: 30px;
            box-sizing: border-box;
            position: absolute;
            z-index: 30;
            right: 20px;
            top: 25px;
            cursor: pointer;
            -webkit-transition: all 0.3s ease-in;
            -moz-transition: all 0.3s ease-in;
            -khtml-transition: all 0.3s ease-in;
            -o-transition: all 0.3s ease-in;
            transition: all 0.3s ease-in;
        }
        .site-nav .icon:before {
            content: "";
            display: block;
            position: absolute;
            height: 2px;
            width: 30px;
            right: 0;
            background: white;
            top: 10px;
            -webkit-transition: all 0.3s ease-in;
            -moz-transition: all 0.3s ease-in;
            -khtml-transition: all 0.3s ease-in;
            -o-transition: all 0.3s ease-in;
            transition: all 0.3s ease-in;
        }
        .site-nav .icon:after {
            content: "";
            display: block;
            position: absolute;
            height: 2px;
            width: 30px;
            right: 0;
            background: white;
            bottom: 0;
            -webkit-transition: all 0.3s ease-in;
            -moz-transition: all 0.3s ease-in;
            -khtml-transition: all 0.3s ease-in;
            -o-transition: all 0.3s ease-in;
            transition: all 0.3s ease-in;
        }
        .nav-visible .site-nav .icon{
            border: transparent;
        }
        .nav-visible .site-nav .icon:before {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -khtml-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
            width: 33px;
            right: -2px;
        }
        .nav-visible .site-nav .icon:after {
            -webkit-transform: rotate(135deg);
            -moz-transform: rotate(135deg);
            -khtml-transform: rotate(135deg);
            -o-transform: rotate(135deg);
            transform: rotate(135deg);
            width: 33px;
            right: -2px;
            top: 10px;
        }
        .nav-visible .site-nav{
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            bottom: 0;
            overflow-x: hidden;
            overflow-y: auto;
            text-align: left;
            z-index: 5;
            background: rgb(44,77,161);
            background: linear-gradient(90deg, #161A1D 0%, #2b2e33 100%);
        }
        .site-header.nav-visible .container{
            display: flow-root;
        }
        .site-nav>ul{
            opacity: 0;
            visibility: hidden;
            display: none;
        }
        .nav-visible .site-nav>ul {
            display: grid;
            justify-content: center;
            align-items: center;
            opacity: 1;
            visibility: visible;
            position: fixed;
            width: 100%;
            height: 70%;
            min-width: 320px;
            top: 70px;
            left: 0;
            bottom: 0;
            overflow-x: hidden;
            overflow-y: auto;
            text-align: center;
        }
        .nav-visible .site-nav>ul>li a{
            font-weight: 600;
            font-size: 20px;
            line-height: 25px;
            text-align: center;
            color: #F3F3F3;
            white-space: break-spaces;
        }

        .nav-visible  .btn-group{
            box-shadow: none;
        }
        .nav-visible .site-nav>ul>li a.btn ,  .nav-visible .btn-group li:not(:last-child):not(.dropdown-toggle) .btn, .nav-visible .btn-group li:not(:first-child) .btn{
            border: none;
            background-color: transparent;
        }
        .nav-visible .site-nav>ul>li a.btn:hover ,  .nav-visible .btn-group li:not(:last-child):not(.dropdown-toggle) .btn:hover, .nav-visible .btn-group li:not(:first-child) .btn:hover{
            background-color: #ffffff40;
            border-radius: 22px;
        }
}
  
/* Table */
.table{
    position: relative;
    z-index: 1;
    background: white;
}
.t-top-title{
    padding-top: 100px;
}

.t-section {
    border-radius: 26px;
    padding: 15px;
    margin-top: 40px;
}
.tabs-wrapper{
    background: #E5E5E5;
    border-radius: 26px;
    padding: 15px;
}
.mytabs {
    display: none;
    height: 0;
    opacity: 0;
}

.mytabs >  div {
    overflow: hidden;
}

.mytabs.active {
    height: auto;
    opacity: 1;
    display: block;
    transition: opacity 0.3s linear;
}

.d-none {
    display: none;
}

table.home-table {
    width: 100%;
    border: 0;
    border-collapse: separate;
    border-spacing: 0 10px;
    margin-top: -10px;
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #2E2056;
}

.home-table thead tr {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #C3E8E4;
    text-transform: uppercase;
}

.home-table tbody tr {
    padding: 5px 5px 5px 20px;
    border-radius: 28px;
    margin: 15px 0;
}

table.home-table td {
    border: solid 1px #f9fbff;
    border-style: solid none;
    padding: 10px;
    background-color: #F9FBFF;
    text-align: center;
    max-width: 250px;
    color: #2E2056;
    position: relative;
}

table.home-table td:not(:first-of-type, :last-of-type, :nth-of-type(5))::after {
    content: '';
    height: 80%;
    width: 1px;
    position: absolute;
    right: 0;
    top: 10px;
    background-color: #F1F4F9;
}

table.home-table td:first-child {
    border-left-style: solid;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

table.home-table td:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
}
table.home-table tr:hover td:first-child, table.home-table tr:hover td, table.home-table tr:hover td:last-child{
    border-color: var(--button-primary-color);
}
table.home-table li{
    margin-left: 20px;
}
.t-logo{
    flex: 0 0 18%;
    background: #F1F4F9;
    border-radius: 25px;
    width: 100%;
    padding: 20px 0;  
}

.t-logo .logo {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 150px;
    width: 150px;
    height: 40px;
    font-size: 0;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
.t-logo .logo.logo-etherland{
    background-image:  url("../image/etherland-logo.webp");
} 
.t-logo .logo.logo-realt{
    background-image:  url("../image/realt-logo.webp");
} 
.t-logo .logo.logo-decentraland{
    background-image:  url("../image/decentraland-logo.webp");
} 
.t-logo .logo.logo-sandbox{
    background-image:  url("../image/sandbox-logo.webp");
} 
.t-logo .logo.logo-embersword{
    background-image:  url("../image/embersword-logo.webp");
}

.t-os-icon{
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: auto;
    height: 30px;
    margin: 0 auto;
    width: 50px;
    display: inline-block;
    vertical-align: middle;
    font-size: 0;
}

.t-rating {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    min-width: 80px;
}
.t-rating .icon{
    content: '';
    width: 20px;
    height: 20px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    float: left;
    margin: 0px 10px 0 0px;
    font-size: 0px;
    background-image: url(../image/star.png);
}
.t-rating img:first-child,
.t-os img:first-child {
    margin-right: 15px;
}

ul.list-highlights {
    text-align: left;
    list-style: none;
    font-weight: 500;
    padding: 0 20px;
    line-height: 1.4;
}

ul.list-highlights li::before {
    content: "\2022";
    font-family: Poppins;
    color: var(--li-circle-color);
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}
@media only screen and (min-width: 868px) and (max-width: 1080px) {
    .t-logo .logo{
        width: 200px;
    }
    .t-rating{
        justify-content: left
    }
}
@media only screen and (max-width: 867px) {
     /* Table */
     .t-section{
        padding: 0;
    }
    .t-top-tabs {
        overflow: hidden;
        display: block;
    }
    .t-top-title{
        background-image: none;
        padding-top: 20px;
    }
    .t-logo {
        background: #F1F4F9;
        border-radius: 25px;
        padding: 20px 10px;
        height: auto;
        width: 100%!important;
    }
    .t-logo .logo {
        display: block;
        width: 100px;
        height: 30px;
        font-size: 0;
        text-indent: -4000px;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        max-width: 100%;
        margin: 0 auto;
        float: none;
    }
    .t-os{
        margin: inherit;
    }
    .t-rating {
        font-size: 14px;
        line-height: 21px;
        color: #2E2056;
    }
    .t-rating{
        justify-content: left
    }
    .home-table thead{
        display: none;
    }
    .home-table tbody tr{
        display: table;
        background: #F9FBFF;
        border-radius: 20px;
        padding: 5px;
        width: 100%;
        max-width: -webkit-fill-available;
    }
    .home-table td {
        border: none!important;
		position: relative;
        text-align: left!important;
        padding: 10px 5px!important;
        display: inline-table;
        max-width: 350px!important;
	}

		.home-table td:before {
			position: absolute;
			top: -6px;
			left: 4px;
			width: 45%;
			padding-right: 10px;
			white-space: nowrap;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            text-align: left;
            text-transform: uppercase;
            color: #96A0AE;

        }
		.home-table td:nth-of-type(1):before { content: ""; }
		.home-table td:nth-of-type(2):before { content: "Type"; }
		.home-table td:nth-of-type(3):before { content: "Highlights"; }
		.home-table td:nth-of-type(4):before { content: "Rating"; }
        
        .home-table td:nth-of-type(1), .home-table td:nth-of-type(2){width: 48%;line-height: 20px;}
        .home-table td:nth-of-type(3){float: right;width: 65%;}
        .home-table td:nth-of-type(4){width: 20%;}
        .home-table td:nth-of-type(5){width: 100%;display: flow-root;}

        table.home-table td:not(:first-of-type, :last-of-type, :nth-of-type(4))::after{
            background-color: transparent;
        }
}

/* Types */

.type{
    border-radius: 22px;
    padding: 0px 40px 20px 40px;
    position: relative;
}
.type::before{
    content: "";
    width: 250px;
    height: 250px;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
}
.type.bg-light::before{
    float: left;
    margin: 0 40px 15% 0;
    background-image: url("../image/digital-icon.webp");
}
.type.bg-dark::before{
    float: right;
    margin: 0 0 10% 50px;
    background-image: url("../image/physical-icon.webp");
}
.type.bg-light{
    top: 30px;
}
.type.bg-dark{
    top: 70px;
}
.bg-light{
    background: #568AD9;
}
.bg-dark{
    background: #3A6BB6;
    box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.15);
}
.type h3{
    padding-top: 40px;
}
.type a{
    color: white;
    text-decoration: underline;
    font-weight: 800;
}
@media only screen and (max-width: 867px) {
    .type{
        padding: 0 20px;
    }
    .type.bg-light::before, .type.bg-dark::before{
        display: block;
        margin: 0 auto 5px;
        float: unset;
    }
    .type h3{
        padding-top: 0px;
    }
}
/* F&Q */
.faq-wrapper {
    padding: 60px 0 80px;

}
.faq-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 20px 30px;
}
.faq-title h3{
    font-size: 20px;
    line-height: 23px;
    color: #454545;
    text-transform: none;
}
.faq.expanded .faq-title{
    padding-bottom: 20px;
    border-bottom: 1px solid var(--bg-box);
}

.faq-btn {
    position: relative;
    float: right;
    height: 20px;
    width: 30px;
}
.faq-btn::after{
    content: "";
    position: absolute;
    right: 0;
    left: 0;
    margin: 0 auto;
    width: 10px;
    height: 10px;
    border-top: 2px solid grey;
    border-left: 2px solid grey;
    transform: rotate(225deg);
    -ms-transform:rotate(225deg);
    -webkit-transform:rotate(225deg);
    -webkit-transition: -webkit-transform .5s ease-in-out;
    -ms-transition: -ms-transform .5s ease-in-out;
    transition: transform .5s ease-in-out;  
}
.faq.expanded .faq-btn::after {
    transform: rotate(45deg);
    -ms-transform:rotate(45deg);
    -webkit-transform:rotate(45deg);
  }

  .faq .faq-btn:hover {
    cursor: pointer;
}

.faq {
    transition: all 0.1s ease-in;
    background-color: white;
    border-radius: 22px;
  
}
.faq+.faq{
    margin-top: 30px;
}
.faq.collapsed .faq-body {
    display: none;
}
.faq-body{
    padding: 0 30px;
}
.faq.expanded {
    background: white;
    border-radius: 22px;
    border: none;
}

.faq.faq.expanded+.faq {
    border-top: 0px;
}

.faq.expanded .faq-title h3 {
    margin-bottom: 0px;
}

.faq .faq-title h3 {
    pointer-events: none;
}

.faq-listing {
    margin-top: 30px;
}

.read-more-faq {
    width: 100%;
    border: 1px solid var(--link-primary-color);
    border-radius: 69px;
    height: 59px;
    background: transparent;
    font-weight: bold;
    font-size: 16px;
    font-family: Poppins;
    font-style: normal;
    line-height: 24px;
    color: var(--link-primary-color);
}

.read-more-faq:active {
    background-color: #E2FCFC;
}

@media only screen and (max-width: 867px) {
    .faq-wrapper {
        margin-top: 30px;
    }
    .faq, .faq-title{
        padding: 10px;
    }
    .faq.expanded {
        border-radius: 15px;
    }
}
.conclution-box {
    background: #2B2E33;
    padding: 30px 130px 50px;
}
.conclution-box .button:hover{
    color: white;
    background-image: linear-gradient(94.06deg, var(--button-primary-color) 1.25%, var(--button-primary-color) 71.25%);
}
.contact-btn-box{    
    text-align: center;
}
.contact-btn-box .button{
    cursor: not-allowed;
}
.contact-btn-box .button:hover:before {
    background: black;
    border-radius: 69px;
    bottom: 0;
    box-shadow: 0 4px 15px rgb(26 70 79 / 30%);
    color: #aaaaaa;
    content: "Services under construction";
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    display: inline;
    padding: 15px 5px;
    height: inherit;
    left: 0;
    opacity: .95;
    position: absolute;
    transform: translateY(-80px);
    width: inherit;
}
.contact-btn-box .button:hover:after {
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-top: 8px solid black;
    clear: both;
    content: "";
    height: 0;
    left: 47%;
    opacity: .95;
    position: absolute;
    transform: translateY(-16px);
    width: 0;
    top: -10px;
}
@media only screen and (max-width: 867px) {
    .conclution-box{
        padding: 15px;
        top: 50px;
    }
}
.footer{
    background: #202022;
}
.footer .footer-inner{
    text-align: center;
    color: #707278;
    padding: 20px 0;
}

@mixin transition($params) {
    -webkit-transition: $params;
       -moz-transition: $params;
     -khtml-transition: $params;
         -o-transition: $params;
            transition: $params;
  }

@mixin rotate($params) {
    -webkit-transform: rotate($params);
       -moz-transform: rotate($params);
     -khtml-transform: rotate($params);
         -o-transform: rotate($params);
            transform: rotate($params);
  }